<template>
  <b-card 
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ $t('Table.Units.Title') }}</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" :to="{ name: 'setups-unit-action', params: { id: 'null' } }">
              <span class="text-nowrap">{{ $t('Create') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
        <b-table 
          :fields="fields" 
          :items="items"
          striped
          responsive
          class="mb-0"
        >
            <template #head()="scope">
               <div
                 class="text-nowrap">
                {{ $t(`Table.Units.${scope.label}`) }}
                </div>
            </template>
            

            <template #cell(Check)="row">
                <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                    class="hidden"
                >
                </b-form-checkbox>
                <feather-icon
                  class="cursor-pointer"
                  :icon="row.detailsShowing ? 'ChevronDownIcon' : 'ChevronRightIcon'" 
                  @click="row.toggleDetails(); fetchDataListUnit(row.item.id, row.detailsShowing);"
                  size="24"
                /> 
                   <span class="menu-title"> {{ $t('Units') }}</span>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
                <b-card v-if="row.item.id === id_group ? 'hidden' : ''">                   
                    <b-col
                      class="mb-2"
                      cols="12"
                      md="12"
                    >
                        <b-button variant="outline-secondary" @click="row.toggleDetails" class="mr-1">
                          <span class="text-nowrap">{{ $t('Hide') }}</span>
                        </b-button>
                        <b-button 
                          id="toggle-btn"
                          variant="primary" 
                          v-b-modal.modal-center 
                        >
                        <span class="text-nowrap">{{ $t('Create') }}</span>
                        </b-button>
                        <!-- Modal  -->
                          <b-modal
                            id="modal-center"
                            centered
                            ref="my-modal"
                            title="Satuan"
                            ok-title="Simpan"
                            cancel-title="Bersihkan"
                            cancel-variant="outline-secondary"
                            @show="resetModal"
                            @hidden="resetModal"
                            @ok="handleOk"
                            size="lg"
                          >
                          <validation-observer
                            ref="warehouseForm"
                          >
                          <b-form 
                            @submit.stop.prevent="handleSubmit"
                          >
                            <b-row>
                              <b-col 
                                lg="12"
                                md="12"
                              >        
                                <div role="group">
                                  <label for="input-live">{{ $t('Form.Units.Name') }}</label>
                                      <validation-provider
                                          #default="{ errors }"
                                          name="Name"
                                          vid="name"
                                          rules="required"
                                      >
                                          <b-form-input
                                              v-model="name"
                                              id="mc-first-name"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                  </div>
                              </b-col>
                              <b-col
                                cols="12"
                              >
                                <div role="group">
                                    <label for="input-live">{{ $t('Form.Units.Quantity') }}</label>
                                    <validation-provider
                                          #default="{ errors }"
                                          name="quantity"
                                          vid="quantity"
                                          rules="required"
                                    >
                                        <b-form-input
                                            v-model="quantity"
                                            type="number"
                                            id="mc-first-name"
                                        />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                              </b-col>
                            </b-row>
                          </b-form>
                          </validation-observer>
                          </b-modal>
                        <!-- End Modal  -->                    
                    </b-col>

                    <b-row>
                      <b-col
                        md="12"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                      >
                            <b-table 
                                :fields="field" 
                                :items="itemsDetail"
                                responsive
                                sticky-header
                                head-variant="light"
                            >
                            <template #head()="scope">
                              <div
                                class="text-nowrap">
                                {{ $t(`Table.Units.${scope.label}`) }}
                                </div>
                            </template>
                            <template #cell(id)="data">
                                <div class="text-nowrap">
                                <feather-icon
                                    @click="handleEditUnit(data.item.id)"
                                    icon="EditIcon"
                                    class="cursor-pointer"
                                    size="20"
                                    v-b-modal.modal-center
                                />

                                <feather-icon
                                    @click="showMsgBoxRemove(data.item.id)"
                                    icon="TrashIcon"
                                    size="20"
                                    class="mx-2 cursor-pointer"
                                />
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                  </b-row>
                </b-card>
            </template>

            <!-- Column: Actions -->
            <template #cell(id)="data">
                <div class="text-nowrap" v-if="data.item.instansi_id === instansi_id">
                    <feather-icon
                        @click="handleUpdate(data.item.id)"
                        icon="EditIcon"
                        class="cursor-pointer"
                        size="20"
                    />

                    <feather-icon
                        @click="showMsgBoxRemoveGroup(data.item.id)"
                        icon="TrashIcon"
                        size="20"
                        class="mx-2 cursor-pointer"
                    />
                </div>
            </template>
        </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Table.Showing') }} {{ $t('Table.Of') }} {{ $t('Table.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { 
  BCard,
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput, 
  BFormCheckbox, 
  BForm, 
  BButton, 
  BTable,
  BFormRadioGroup, 
  BFormTextarea, BModal, VBModal, BCardText, BPagination, BBadge, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import store from '@/store/index'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getUserData } from '@/auth/utils'

export default {
  components: { 
    BCard,
    BRow, 
    BCol, 
    BFormGroup, 
    BFormInput, 
    BFormCheckbox, 
    BForm, 
    BButton, 
    BFormRadioGroup, 
    BFormTextarea, BModal, VBModal, BCardText,  BTable, BPagination, BBadge, BTooltip,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      instansi_id: '',
      pageLength: 10,
      fields: [
        'Check',
        { key: 'title', label: 'Group' },        
        { key: 'description', label: 'Description' },
        { key: 'id', label: 'Actions' },
      ],
      items: [],
      field: [
        { key: 'title', label: 'Name' }, 
        { key: 'out', label: 'Quantity' },    
        { key: 'id', label: 'Actions' },
      ],
      itemsDetail: [],
      id_group: '',
      status_checkbox: '',
      unit_id: '',
      name: '',
      quantity: '',
      required,
    }
  },
  methods: {
    fetchDataListGroupUnits() { 
      moduleApi.fetchDataListGroupUnits()
        .then(response => {
            this.items  = response.data.data
            this.fetchDataListGroupUnitsByInstansi()
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListGroupUnitsByInstansi() { 
      moduleApi.fetchDataListGroupUnitsByInstansi(this.instansi_id)
        .then(response => {
            this.items.push(...response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListUnit(id, status) { 
      if(!status){
        moduleApi.fetchDataListUnitsByGroup(id)
        .then(response => {
            this.itemsDetail = response.data.data
            this.id_group = id
        })
        .catch(error => {
          console.log(error)
        })
      }
      this.itemsDetail = []
    },
    resetModal() {
      this.name = ''
      this.quantity = ''
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() { 
        if(this.unit_id === ''){
            moduleApi.createUnits({
                title: this.name,
                out: this.quantity,
                group_id: this.id_group
            })
            .then(response => {
                this.fetchDataListUnit(this.id_group, false)
                this.$refs['my-modal'].toggle('#toggle-btn')           
            })
            .catch(error => {
                console.log(error)
            })
        } else {
            moduleApi.updateUnits({
                title: this.name,
                out: this.quantity,
                id: this.unit_id
            })
            .then(response => {
                this.fetchDataListUnit(this.id_group, false)
                this.$refs['my-modal'].toggle('#toggle-btn') 
                this.unit_id = ''         
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    handleEditUnit(id) {
        moduleApi.fetchDataListUnit(id)
        .then( response => {
            this.name = response.data.data.title
            this.quantity = response.data.data.out
            this.unit_id = response.data.data.id
        })
        .catch( error => {
          console.log(error)
        })
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.handleRemoveUnit(id)
          }
        })
    },
    handleRemoveUnit(id) {
        moduleApi.deleteUnits(id)
        .then( response => {
            this.fetchDataListUnit(this.id_group, false)
        })
        .catch( error => {
          console.log(error)
        })
    },

    // group
    handleUpdate (id) {
      this.$router.replace({name: 'setups-unit-action', params: { id: id }})
    },
    showMsgBoxRemoveGroup(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.handleRemove(id)
          }
        })
    },
    handleRemove (id) {
      moduleApi.deletedGroupUnit(id)
        .then( response => {
            this.fetchDataListGroupUnits()
        })
        .catch( error => {
          console.log(error)
        })
    },
  },
  created () {
    const userData = getUserData()
    this.instansi_id = userData.idInstansi
    this.fetchDataListGroupUnits()  
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>